import React from 'react';
import '../css/style.scss';
import {LanguageConsumer, stringsCookies} from '../Localisation';

function TranslatableMainText(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsCookies.setLanguage(language);
                props.language[language] = stringsCookies;

                return (
                    <p>
                        This website uses cookies to ensure you get the best experience. Our{' '}
                        <a href="/personalinfo">Privacy Policy</a> and{' '}
                        <a href="/cookies">Cookies Policy</a> explain the data we collect, why we
                        collect them, and how we may share them.
                    </p>
                );
            }}
        </LanguageConsumer>
    );
}
function TranslatabLable(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsCookies.setLanguage(language);
                props.language[language] = stringsCookies;

                return stringsCookies.cookiesPopup_agree;
            }}
        </LanguageConsumer>
    );
}

class Cookies extends React.Component {
    constructor() {
        super();
        this.state = {
            CookieControl: true,
            isAgreementChecked: false,
        };
    }

    componentDidMount() {
        this.checkCookie();
    }

    checkCookie() {
        const CookieControl = this.getCookie('CookieControl');
        if (CookieControl) {
            this.setState({
                CookieControl: true,
            });
        } else {
            this.setState({
                CookieControl: false,
            });
        }
    }

    setCookie(cname, cvalue) {
        document.cookie = `${cname}=${cvalue};path=/`;
    }

    getCookie(cname) {
        const name = `${cname}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    clickCheckbox = () => {
        const old_isAgreementChecked = this.state.isAgreementChecked;
        const isAgreementChecked = !old_isAgreementChecked;
        this.setState({
            isAgreementChecked,
        });
    };

    handleChange = () => {
        const CookieControl = this.state.isAgreementChecked;
        this.setCookie('CookieControl', CookieControl);
        this.setState({
            CookieControl,
        });
    };

    render() {
        return (
            <div
                className={
                    this.state.CookieControl
                        ? 'cookieControl cookieControl_hide'
                        : 'cookieControl cookieControl_show'
                }
            >
                <section>
                    <TranslatableMainText language={{}} />
                    <label className="checkbox_wrapper">
                        <input
                            className="checkbox"
                            type="checkbox"
                            name="future"
                            checked={this.state.isAgreementChecked}
                            onChange={this.handleChange}
                            id="future"
                        />
                        <span
                            style={{background: 'white'}}
                            className="checkbox-custom trigger_filter"
                            id="future_check"
                            onClick={this.clickCheckbox}
                        />
                        <span className="label" onClick={this.clickCheckbox}>
                            <TranslatabLable language={{}} />
                        </span>
                    </label>
                </section>
            </div>
        );
    }
}

export default Cookies;
