import React from 'react';
import {Link} from 'react-router-dom';
import {LanguageConsumer, stringsContacts, stringsNotFound} from '../Localisation';
import {getCookie, setCookie} from '../cookies';

const Images = {
    dunce: require('../img/dunce.png'),
};

function TranslatableText(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsNotFound.setLanguage(language);
                props.language[language] = stringsNotFound;
                return (
                    <div className="main_404">
                        <div className="err_wrap">
                            <img title="dunce" className="main_404_img" src={Images.dunce} />
                        </div>
                        <div className="error_descr_wpapper">
                            <p className="error_descr">{stringsNotFound.title}</p>
                            <Link className="return_main" to="/">
                                {stringsNotFound.link}
                            </Link>
                        </div>
                    </div>
                );
            }}
        </LanguageConsumer>
    );
}

class NotFound extends React.Component {
    render() {
        return <TranslatableText language={{}} />;
    }
}

export default NotFound;
