import React from 'react';
import '../../css/style.scss';

function Announce() {
    return (
        <div className="homepage_announce">
            <a
                rel="noopener noreferrer"
                href={`${window.location.origin.toString()}/personalpage/login`}
                target="_self"
            >
                JOIN THE S&P 500 CONTEST: $2M IN ALLOCATIONS, $1M FOR THE 1ST PLACE!
            </a>
        </div>
    );
}

export default Announce;
