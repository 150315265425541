import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/style.scss';
import App from './router/App';
import * as serviceWorker from './serviceWorker';
import './Localisation';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

serviceWorker.unregister();
