import React from 'react';
import '../../css/style.scss';
import styled from 'styled-components';

const Images = {
    bls: require('./bls.webp'),
    imf: require('./imf.webp'),
    fundamental: require('./fundamental.webp'),
    background: require('../../img/homepage/background_blue_graph.webp'),
};

const AboutDatasetDiv = styled.div`
    text-align: center;
    font-size: 18px;
    line-height: 30px;

    background-image: url(${Images.background});
    background-repeat: repeat;
    color: rgb(174, 173, 173);

    img {
        display: none;
        width: 100%;
        max-width: 480px;
    }
    .dataset_header {
        font-size: 20px;
        line-height: 1.5;
        color: rgb(232, 230, 227);
    }
    .dataset {
        display: flex;
        margin: auto;
        max-width: 1380px;
        justify-content: space-evenly;
        align-items: stretch;
        padding-bottom: 20px;
    }
    .dataset_description {
    }

    @media (min-width: 768px) {
        img {
            display: inline;
        }
        .dataset_description {
            width: 50%;
            display: flex;
            margin: auto;
            align-items: center;
            flex-direction: column;
        }
    }
`;

function AboutDatasetAdditional() {
    return (
        <AboutDatasetDiv className="quantiacs_in_the_press">
            <section>
                <h2 className="section_header">Additional datasets</h2>
                <div className="datasets_all">
                    <div className="dataset">
                        <div>
                            <img src={Images.bls} alt="BLS Data" />
                        </div>
                        <div className="dataset_description">
                            <div className="dataset_header">Using BLS Data</div>
                            <p>
                                The U.S. Bureau of Labor Statistics is the principal agency for the
                                U.S. government in the field of labor economics and statistics. It
                                provides macroeconomic data in several interesting categories:
                                prices, employment and unemployment, compensation and working
                                conditions and productivity.
                            </p>
                        </div>
                    </div>
                    <div className="dataset">
                        <div>
                            <img src={Images.imf} alt="IMF Data" />
                        </div>
                        <div className="dataset_description">
                            <div className="dataset_header">Using IMF Data</div>
                            <p>
                                The International Monetary Fund (IMF) publishes a range of time
                                series data on IMF lending, exchange rates and other economic and
                                financial indicators and information about commodity data.
                            </p>
                        </div>
                    </div>
                    <div className="dataset">
                        <div>
                            <img src={Images.fundamental} alt="Fundamental Data" />
                        </div>
                        <div className="dataset_description">
                            <div className="dataset_header">Using Fundamental Data</div>
                            <p>
                                Fundamental Data provides insights into the financial health of
                                publicly traded companies. This dataset is based on official
                                publications from the SEC, allowing users to access key financial
                                information such as assets, liabilities, revenue, and more.
                                Quantiacs leverages these data to help you analyze company
                                performance and make informed trading decisions.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </AboutDatasetDiv>
    );
}

export default AboutDatasetAdditional;
