// A Function to Get a Cookie
export const getCookie = (cname) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

// A Function to Set a Cookie
export const setCookie = (cname, cvalue, exseconds) => {
    const value = encodeURIComponent(cvalue);
    const d = new Date();
    d.setTime(d.getTime() + exseconds * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${value};${expires};path=/`;
};

// A Function to Check a Cookie
export const checkCookie = (cname) => {
    const cookie = getCookie(cname);
    if (cookie != '') {
        return cookie;
    }
    return false;
};
