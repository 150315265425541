import React from 'react';
import '../../css/style.scss';
import styled from 'styled-components';

const AboutCompetitionPrizeCalculatorDiv = styled.div`
    display: flex;
    margin: 0 auto;
    max-width: 1180px;
    align-items: center;
    text-align: center;

    div {
        margin: 10px;
        line-height: 19.5px;
    }
    div,
    a,
    span {
        color: white;
    }

    .dropdown_btn {
        display: inline-block;
        margin-bottom: 0;
        font-size: 15px;
        line-height: 19.5px;
        cursor: pointer;
        color: #333;
        border-radius: 0;
        font-weight: bold;
        padding: 5px 5px 5px 15px;
        transition: all 0.2s ease 0s;
        border: 1px solid #ccc;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    }

    .percent_input {
        display: inline-block;
        margin-bottom: 0;
        font-size: 15px;
        line-height: 19.5px;
        color: #333;
        border-radius: 0;
        box-shadow: none;
        font-weight: bold;
        padding: 5px 5px 5px 15px;
        transition: all 0.2s ease 0s;
        border: 1px solid #ccc;
        width: 65px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    }

    .caret {
        margin-top: 8px;
        margin-left: 5px;
        display: inline-block;
        width: 0;
        height: 0;
        vertical-align: top;
        border-top: 4px solid black;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        content: '';
    }

    .prize_size_dropdown_menu {
        position: absolute;
        display: none;
        z-index: 1000;
        left: 30%;
        min-width: 120px;
        padding: 5px 0;
        margin: 2px 0 0;
        list-style: none;
        background-color: white;
        border: 1px solid #ccc;
        border: 1px solid rgba(0, 0, 0, 0.2);
        *border-right-width: 2px;
        *border-bottom-width: 2px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        -webkit-background-clip: padding-box;
        -moz-background-clip: padding;
        background-clip: padding-box;

        li {
            display: block;
            padding: 3px 20px;
            clear: both;
            font-weight: normal;
            line-height: 22px;
            color: #333;
            white-space: nowrap;
        }

        li:hover {
            color: #2196f3;
            cursor: pointer;
        }
    }

    .open {
        display: block;
    }

    strong {
        font-weight: bold;
        color: #ffffff;
    }

    a {
        line-height: 19.5px;
    }

    @media screen and (max-width: 1605px) {
        .prize_size_dropdown_menu {
            left: 26%;
        }
    }

    @media screen and (max-width: 1105px) {
        display: block;
        text-align: justify;
        .prize_size_dropdown_menu {
            left: 260px;
        }
    }

    @media screen and (max-width: 405px) {
        .prize_size_dropdown_menu {
            left: 35%;
        }
    }
`;

class AboutCompetitionPrizeCalculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            financialGoal: 20,
            prize: 1000000,
            isPrizeSizeMenuOpen: false,
        };
    }

    handleChange = (event) => {
        const financialGoal = event.target.validity.valid
            ? event.target.value
            : this.state.financialGoal;

        this.setState({financialGoal});
    };

    handleOpenPrizeSize = () => {
        this.setState({isPrizeSizeMenuOpen: true});
    };

    handleClosePrizeSize = (prize) => {
        this.setState({isPrizeSizeMenuOpen: false, prize});
    };

    getPrizeFormat(money) {
        const formatter = new Intl.NumberFormat('en');
        return formatter.format(money);
    }

    render() {
        const styleForPrizeMenu = this.state.isPrizeSizeMenuOpen ? 'open' : '';
        const userPercentProfit = 0.1;
        const profit = this.state.prize * (this.state.financialGoal / 100) * userPercentProfit;
        const profitCorrector = profit < 0 ? 0 : profit;
        return (
            <AboutCompetitionPrizeCalculatorDiv>
                <div className="dropdown">
                    <span>Allocated funds for trading $ </span>
                    <button
                        className="dropdown_btn"
                        type="button"
                        onClick={this.handleOpenPrizeSize}
                    >
                        {this.getPrizeFormat(this.state.prize)}
                        <span className="caret"></span>
                    </button>
                    <ul className={`prize_size_dropdown_menu ${styleForPrizeMenu}`}>
                        <li onClick={() => this.handleClosePrizeSize(1000000)}>1,000,000</li>
                        <li onClick={() => this.handleClosePrizeSize(500000)}>500,000</li>
                        <li onClick={() => this.handleClosePrizeSize(250000)}>250,000</li>
                        <li onClick={() => this.handleClosePrizeSize(100000)}>100,000</li>
                        <li onClick={() => this.handleClosePrizeSize(50000)}>50,000</li>
                    </ul>
                </div>
                <div>
                    <span>The profitability of the strategy % </span>
                    <input
                        className="percent_input"
                        type="number"
                        pattern="[0-9]*"
                        onInput={this.handleChange}
                        value={this.state.financialGoal}
                    />
                </div>
                <div>
                    <a href="/contest/15#profit">Expected User Prize</a>
                    <strong> {this.getPrizeFormat(profitCorrector)}</strong>$
                </div>
            </AboutCompetitionPrizeCalculatorDiv>
        );
    }
}

export default AboutCompetitionPrizeCalculator;
