import React from 'react';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {FaStar, FaTrophy, FaChartLine, FaBolt, FaBitcoin} from 'react-icons/fa';
import styled from 'styled-components';
import backgroundImage from '../../img/homepage/background_blue_graph_2.webp';

const CompanyHistoryDiv = styled.div`
    background: #223c55;
    background-image: url(${backgroundImage}), linear-gradient(to bottom, #223c55, #1e1e2f);
    padding: 20px;
    color: #f0f0f0;

    .vertical-timeline-element-content {
        background: #1e1e2f;
        color: #fff;
        border-radius: 8px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }

    .vertical-timeline-element-content-arrow {
        border-right: 7px solid #1e1e2f;
    }

    .vertical-timeline-element-title {
        color: #4caf50;
        line-height: 1.8;
        font-weight: bold;
    }

    .vertical-timeline-element-subtitle {
        color: #f0f0f0;
        line-height: 1.8;
    }

    a {
        color: #00bcd4;
        text-decoration: underline;
        line-height: 1.8;
    }

    a:hover {
        color: #03a9f4;
        text-decoration: none;
    }

    .section_header {
        color: #a9a095;
        text-align: center;
        text-transform: none;
        font-family: 'Gotham Pro Medium', sans-serif;
        font-weight: normal;
        margin-top: 0;
        font-size: 2.25rem;
        margin-bottom: 2.5rem;
    }

    p {
        font-size: 1.125rem;
        color: #e0e0e0;
        line-height: 1.6;
        margin: 20px auto;
        max-width: 800px;
        text-align: center;
    }

    @media (max-width: 768px) {
        p {
            font-size: 1rem;
            margin: 15px 10px;
        }

        .section_header {
            font-size: 1.75rem;
            margin-bottom: 1.875rem;
        }
    }
`;

function CompanyHistory() {
    const events = [
        {
            title: '2025 (Upcoming)',
            cardTitle: 'Q22 S&P 500 Long Short Competition',
            cardSubtitle:
                'A competition with a $2M USD prize pool. Deadline: January 31, 2025. Live contest period: February 1, 2025 - May 31, 2025',
            icon: <FaTrophy />,
            iconStyle: {
                background: 'linear-gradient(45deg, #ffeb3b, #ffc107)',
                color: '#fff',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
            },
        },
        {
            title: '2021-Present',
            cardTitle: 'Expansion into Cryptocurrencies and Stocks',
            cardSubtitle:
                'Competitions expanded to include futures, cryptocurrencies (Bitcoin and most liquid cryptocurrencies), and stocks (NASDAQ-100, S&P 500)',
            icon: <FaBitcoin />,
            iconStyle: {
                background: 'linear-gradient(45deg, #ff9800, #ff5722)',
                color: '#fff',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
            },
        },
        {
            title: '2016-2018',
            cardTitle: 'Growth & Forbes Feature',
            cardSubtitle:
                'Surpassed 10,000 registered quants and featured in Forbes for platform achievements',
            url: 'https://www.forbes.com/sites/tomgroenfeldt/2017/03/07/quantiacs-a-quant-hedge-fund-built-with-freelancers/',
            icon: <FaChartLine />,
            iconStyle: {
                background: 'linear-gradient(45deg, #2196f3, #1e88e5)',
                color: '#fff',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
            },
        },
        {
            title: '2014-2021',
            cardTitle: 'Futures Competitions',
            cardSubtitle:
                'Quantiacs organized 14 futures competitions, awarding over $32M USD in prizes',
            icon: <FaBolt />,
            iconStyle: {
                background: 'linear-gradient(45deg, #ff7043, #f4511e)',
                color: '#fff',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
            },
        },
        {
            title: '2014',
            cardTitle: 'Quantiacs Founded',
            cardSubtitle: 'Founded in Palo Alto with $2.25M in allocated capital',
            icon: <FaStar />,
            iconStyle: {
                background: 'linear-gradient(45deg, #42a5f5, #1976d2)',
                color: '#fff',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
            },
        },
    ];

    const reversedEvents = [...events].reverse();

    return (
        <CompanyHistoryDiv>
            <h2 className="section_header">About the Quantiacs</h2>
            <p>
                Quantiacs drives innovation in quantitative trading by offering a platform for
                traders worldwide to showcase their strategies and be rewarded for their
                achievements.
            </p>
            <VerticalTimeline>
                {reversedEvents.map((event, index) => (
                    <VerticalTimelineElement
                        key={index}
                        date={event.title}
                        iconStyle={event.iconStyle}
                        icon={event.icon}
                    >
                        <h3 className="vertical-timeline-element-title">{event.cardTitle}</h3>
                        <h4 className="vertical-timeline-element-subtitle">{event.cardSubtitle}</h4>
                        {event.url && (
                            <a href={event.url} target="_blank" rel="noopener noreferrer">
                                Learn more
                            </a>
                        )}
                    </VerticalTimelineElement>
                ))}
            </VerticalTimeline>
        </CompanyHistoryDiv>
    );
}

export default CompanyHistory;
