import React from 'react';
import '../../css/style.scss';
import {Link} from 'react-scroll';
import {LanguageConsumer, stringsHomepage} from '../../Localisation';

const Images = {
    learn: require('../../img/homepage/how_it_works_1_learn.webp'),
    submit: require('../../img/homepage/how_it_works_2_submit.webp'),
    evaluate: require('../../img/homepage/how_it_works_3_evaluate.webp'),
    win: require('../../img/homepage/how_it_works_4_win.webp'),
    allocate: require('../../img/homepage/how_it_works_5_allocate.webp'),
    trade: require('../../img/homepage/how_it_works_6_trade.webp'),
    profit: require('../../img/homepage/how_it_works_7_profit.webp'),
    background: require('../../img/homepage/background_blue_dark_4_g.webp'),
};

function TranslatableTitle(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsHomepage.setLanguage(language);
                return (props.language[language] = stringsHomepage.offeringTitle);
            }}
        </LanguageConsumer>
    );
}

class Proposals extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="thurd_line">
                <div className="body_offer">
                    <h2 className="section_header">
                        <TranslatableTitle language={{}} />
                    </h2>
                    <div className="offer_container">
                        <div className="offer_item learn">
                            <div className="offer_p_wrapper">
                                <img src={Images.learn} />

                                <div className="offer_p">
                                    <a href="/documentation/en/">Learn</a>, develop and test your
                                    Trading Algorithm on Quantiacs
                                </div>
                            </div>
                        </div>

                        <div className="offer_item strategies">
                            <div className="offer_p_wrapper">
                                <img src={Images.submit} />

                                <div className="offer_p">
                                    Submit your Trading Algorithm and pass the{' '}
                                    <a href="/contest#rules">filters</a>
                                </div>
                            </div>
                        </div>

                        <div className="offer_item prize">
                            <div className="offer_p_wrapper">
                                <img src={Images.evaluate} />

                                <div className="offer_p">
                                    We evaluate your algorithm on fresh market data during the Live
                                    Contest Period
                                </div>
                            </div>
                        </div>

                        <div className="offer_item prize">
                            <div className="offer_p_wrapper">
                                <img src={Images.win} />

                                <div className="offer_p">
                                    Take one of the top{' '}
                                    <Link to="prize" smooth>
                                        7 places
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="offer_container">
                        <div className="offer_item learn">
                            <div className="offer_p_wrapper">
                                <img src={Images.allocate} />

                                <div className="offer_p">
                                    Quantiacs allocates{' '}
                                    <Link to="prize" smooth>
                                        2M USD
                                    </Link>{' '}
                                    to each Contest
                                </div>
                            </div>
                        </div>

                        <div className="offer_item strategies">
                            <div className="offer_p_wrapper">
                                <img src={Images.trade} />
                                <p className="offer_p">
                                    Your algorithm is traded and generates profit
                                </p>
                            </div>
                        </div>

                        <div className="offer_item prize">
                            <div className="offer_p_wrapper">
                                <img src={Images.profit} />
                                <div className="offer_p">
                                    You receive{' '}
                                    <Link to="prize" smooth>
                                        10% of the profit
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Proposals;
