import React from 'react';
import '../../css/style.scss';
import styled from 'styled-components';

const Images = {
    userTimeroot: require('../../img/homepage/timeroot.webp'),
    userAnon: require('../../img/homepage/anon.webp'),
    userDaniel: require('../../img/homepage/daniel.jpeg'),
    userAhmed: require('./ahmed.webp'),
    userEmiliano: require('./emiliano.webp'),
    userIvan: require('./ivan.webp'),
    background: require('../../img/homepage/background_blue_dark_3_g.webp'),
};

const AboutPressDiv = styled.div`
    max-width: 100%;
    padding: 0;

    .section_header {
        text-align: center;
        font-size: 36px;
        margin-bottom: 40px;
    }

    .contests_winners {
        margin-top: 40px;
    }

    .quantiacs_medium_link {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;

        a {
            line-height: 30px;
            color: #ffffff;
            background: #1bbafb;
            border: none;
            cursor: pointer;
            padding: 5px 15px;
            text-decoration: underline;
            font-size: 1em;
            font-weight: bold;
            -webkit-transition: all 300ms ease-in;
            transition: all 300ms ease-in;
            min-width: 200px;
            width: 250px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 15px;
            display: block;
            border-radius: 10px;
            box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px,
                rgb(0 0 0 / 12%) 0px 1px 5px 0px;
        }
    }

    .section_text {
        white-space: pre-wrap; /* css-3 */
        white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
        white-space: -pre-wrap; /* Opera 4-6 */
        white-space: -o-pre-wrap; /* Opera 7 */
        word-wrap: break-word; /* Internet Explorer 5.5+ */
        line-height: 30px;
        //text-align: center;
        max-width: 1180px;
        margin: 0 auto;
        text-align: justify;

        .infographic {
            width: 80%;
        }
    }

    .quantiacs_in_the_press_container {
        margin: auto;
        max-width: 1380px;
        display: flex;
        justify-content: space-evenly;
        align-items: stretch;
        padding-bottom: 20px;

        .offer_item {
            word-wrap: break-word;
            text-align: center;

            border-radius: 5px;
            margin-top: 0;
            width: 300px;
            margin-right: 10px;
            margin-left: 10px;

            a {
                color: #219ff7;
                line-height: 30px;
                font-size: 1.17em;
            }

            .anonymous_user {
                color: white;
                line-height: 30px;
                font-size: 1.17em;
            }

            .success_stories_img {
                max-height: 150px;
            }
        }

        .press_logo {
            max-height: 18px;
            vertical-align: middle;
            margin-right: 1%;
        }
    }

    section {
        padding: 50px;

        p > a {
            padding-left: 5px;
        }

        p > a > strong {
            font-weight: bold;
        }

        p > strong {
            font-weight: bold;
        }

        .section_ul {
            list-style-type: disc;
            list-style-position: inside;
            padding-left: 40px;
        }

        p > ul {
            list-style-type: disc;
            list-style-position: inside;
            padding-left: 40px;
        }

        p > ol {
            list-style-type: decimal;
            list-style-position: inside;
            padding-left: 40px;
        }

        ol li::marker {
            font-weight: bold;
        }

        > ul ul,
        > ol ul {
            list-style-type: circle;
            list-style-position: inside;
            margin-left: 15px;
            margin-top: 5px;
        }

        > ol ol,
        > ul ol {
            list-style-type: lower-latin;
            list-style-position: inside;
            margin-left: 15px;
        }

        &.dark_theme {
            background: #223c55;
            background-image: url(${Images.background});

            h2,
            h3 {
                color: #f0f5f7;
            }

            .section_text {
                margin-bottom: 20px;
                margin-top: 5px;
                color: #f0f5f7;
                font-size: 0.9em;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .quantiacs_in_the_press_container {
            flex-direction: column;
            align-items: center;

            .offer_item {
                width: 100%;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .section_text {
                text-align: center;
            }
        }
    }

    .denDlG a {
        text-align: center;
        color: #ffffff;
        background: #1bbafb;
        border: none;
        cursor: pointer;
        padding: 5px 15px;
        -webkit-text-decoration: none;
        text-decoration: none;
        font-size: 1em;
        font-weight: bold;
        -webkit-transition: all 300ms ease-in;
        transition: all 300ms ease-in;
        min-width: 200px;
        width: 200px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
        display: block;
        box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px,
            rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    }
`;

function AboutPress() {
    return (
        <AboutPressDiv>
            <section className="dark_theme">
                <h2 className="section_header">Success Stories</h2>
                <div className="quantiacs_in_the_press_container">
                    <div className="offer_item">
                        <div>
                            <img
                                className="success_stories_img"
                                src={Images.userTimeroot}
                                alt="user Timeroot"
                            />
                        </div>
                        <a href="https://quantiacs.com/community/topic/19/interview-with-alex-trust-the-numbers">
                            Trust the Numbers
                        </a>
                        <p className="section_text">
                            Meet Alex: a Physics PhD student at UC Santa Barbara who got 1M USD in
                            allocations and currently earns a quant fee for his work.
                        </p>
                    </div>

                    <div className="offer_item">
                        <div>
                            <img
                                className="success_stories_img"
                                src={Images.userAnon}
                                alt="user anonymous"
                            />
                        </div>
                        <div className="anonymous_user">Anonymous user</div>

                        <p className="section_text">
                            S. is currently being paid in Bitcoin for his systems. We know that he
                            used his first payouts to take his wife on a honeymoon to Bali.
                        </p>
                    </div>

                    <div className="offer_item">
                        <div>
                            <img
                                className="success_stories_img"
                                src={Images.userDaniel}
                                alt="user Daniel"
                            />
                        </div>
                        <a href="https://quantiacs.com/community/topic/20/interview-with-daniel-treat-data-like-gold">
                            Treat Data like Gold
                        </a>
                        <p className="section_text">
                            Meet Daniel: a mechatronics engineer and profi trader who got 2M USD in
                            allocations and now earns a quant fee from Quantiacs.
                        </p>
                    </div>
                </div>

                <div className="quantiacs_in_the_press_container">
                    <div className="offer_item">
                        <div>
                            <img
                                className="success_stories_img"
                                src={Images.userEmiliano}
                                alt="user Emiliano"
                            />
                        </div>
                        <a href="https://quantiacs.com/community/topic/59/interview-with-emiliano-professor-and-quant">
                            Interview on Quantiacs with Emiliano, Professor and Quant
                        </a>
                        <p className="section_text">
                            Emiliano joined Quantiacs in late 2020 and sent to Quantiacs many
                            suggestions which proved very useful for developing our platform.
                        </p>
                    </div>

                    <div className="offer_item">
                        <div>
                            <img
                                className="success_stories_img"
                                src={Images.userIvan}
                                alt="user Ivan"
                            />
                        </div>
                        <a href="https://quantiacs.com/community/topic/170/interview-with-ivan-winner-of-the-q15-quantiacs-contest">
                            Interview with Ivan, Winner of the Q15 Quantiacs Contest
                        </a>
                        <p className="section_text">
                            Ivan joined Quantiacs in 2021 and won the Q15 futures contest. His
                            system has got an allocation of 1M USD.
                        </p>
                    </div>

                    <div className="offer_item">
                        <div>
                            <img
                                className="success_stories_img"
                                src={Images.userAhmed}
                                alt="user Ahmed"
                            />
                        </div>
                        <a href="https://quantiacs.com/community/topic/171/interview-with-ahmed-phd-candidate-at-insead">
                            Interview with Ahmed, PhD Candidate at INSEAD
                        </a>
                        <p className="section_text">
                            Ahmed Guecioueur is a PhD candidate at INSEAD, a leading graduate
                            business school. He used the Quantiacs platform for his research and we
                            met him for an interview.
                        </p>
                    </div>
                </div>
                <p className="quantiacs_medium_link">
                    <a
                        rel="noopener noreferrer"
                        href="https://quantiacs.medium.com/"
                        target="_blank"
                    >
                        Read more on Medium
                    </a>
                </p>
            </section>
        </AboutPressDiv>
    );
}

export default AboutPress;
