import React from 'react';
import LocalizedStrings from 'react-localization';
import PlatformConfigurator from './0_configuration/PlatformConfigurator';

function getBaseLocalisationDir() {
    if (PlatformConfigurator.isQuantiacs()) {
        return './0_configuration/localisationQuantiacs';
    }
    throw new Error('Undefined platform config');
}

const baseDir = getBaseLocalisationDir();

const textHomepage = require(`${baseDir}/homepage`);
export const stringsHomepage = new LocalizedStrings(textHomepage);

const textMenu = require(`${baseDir}/menu`);
export const stringsMenu = new LocalizedStrings(textMenu);

const textCareer = require(`${baseDir}/career`);
export const stringsCareer = new LocalizedStrings(textCareer);

const textStudents = require(`${baseDir}/students`);
export const stringsStudents = new LocalizedStrings(textStudents);

const textStatistics = require(`${baseDir}/statistics`);
export const stringsStatistics = new LocalizedStrings(textStatistics);

const textStatisticsUsers = require(`${baseDir}/statisticsUsers`);
export const stringsStatisticsUsers = new LocalizedStrings(textStatisticsUsers);

const textCompetitionsResults = require(`${baseDir}/competitionsResults`);
export const stringsCompetitionsResults = new LocalizedStrings(textCompetitionsResults);

const textInterviews = require(`${baseDir}/interviews`);
export const stringsInterviews = new LocalizedStrings(textInterviews);

const textContacts = require(`${baseDir}/contacts`);
export const stringsContacts = new LocalizedStrings(textContacts);

const textTermsOfUse = require(`${baseDir}/termsOfUse`);
export const stringsTermsOfUse = new LocalizedStrings(textTermsOfUse);

const textPersonalData = require(`${baseDir}/personalData`);
export const stringsPersonalData = new LocalizedStrings(textPersonalData);

const textCookies = require(`${baseDir}/cookies`);
export const stringsCookies = new LocalizedStrings(textCookies);

const textEvents = require(`${baseDir}/events`);
export const stringsEvents = new LocalizedStrings(textEvents);

const textFirstBitcoinContest = require(`${baseDir}/firstBitcoinContest`);
export const stringsFirstBitcoinContest = new LocalizedStrings(textFirstBitcoinContest);

const textOpenpositions = require(`${baseDir}/openpositions`);
export const stringsOpenpositions = new LocalizedStrings(textOpenpositions);

const textLearn = require(`${baseDir}/learn`);
export const stringsLearn = new LocalizedStrings(textLearn);

const textContest = require(`${baseDir}/contest`);
export const stringsContest = new LocalizedStrings(textContest);

const textQuant = require(`${baseDir}/job_quant`);
export const stringsQuant = new LocalizedStrings(textQuant);

const textUx = require(`${baseDir}/job_ux`);
export const stringsUx = new LocalizedStrings(textUx);

const textPr = require(`${baseDir}/job_pr`);
export const stringsPr = new LocalizedStrings(textPr);

const textSmm = require(`${baseDir}/job_smm`);
export const stringsSmm = new LocalizedStrings(textSmm);

const textCommunity = require(`${baseDir}/job_community`);
export const stringsCommunity = new LocalizedStrings(textCommunity);

const textErrorMsg = require(`${baseDir}/errorMsg`);
export const stringsErrorMsg = new LocalizedStrings(textErrorMsg);

const textNotFoundMsg = require(`${baseDir}/404`);
export const stringsNotFound = new LocalizedStrings(textNotFoundMsg);

const textFaq = require(`${baseDir}/faq`);
export const stringsFaq = new LocalizedStrings(textFaq);

export const LanguageContext = React.createContext(undefined);
export const LanguageConsumer = LanguageContext.Consumer;
