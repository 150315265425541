import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-regular-svg-icons';
import MobileMenu from './MobileMenu';
import Menu from './Menu';
import {LanguageConsumer, stringsMenu} from '../Localisation';
import {getCookie, setCookie} from '../cookies';
import PlatformConfigurator from '../0_configuration/PlatformConfigurator';

function TranslatableAccount(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                return (props.language[language] = stringsMenu.account);
            }}
        </LanguageConsumer>
    );
}
function TranslatableLinks(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                return (props.language[language] = stringsMenu.myAccount);
            }}
        </LanguageConsumer>
    );
}
function TranslatableControlpanel(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                return (props.language[language] = stringsMenu.controlpanel);
            }}
        </LanguageConsumer>
    );
}
function TranslatableLogOut(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                return (props.language[language] = stringsMenu.signOut);
            }}
        </LanguageConsumer>
    );
}

class Header extends React.Component {
    constructor(props) {
        super(props);
        const language = getCookie('language')
            ? getCookie('language')
            : stringsMenu.getInterfaceLanguage();
        this.state = {
            language: language.substring(0, 2),
            activeMenu: 'usernameLinkWrapper_unactive',
            authorities: [],
        };
    }

    componentDidMount() {
        const access_token = getCookie('access_token');
        const refresh_token = getCookie('refresh_token');
        const username = getCookie('username');
        const that = this;
        if ((access_token || refresh_token) && username && username !== '') {
            this.setState(
                {
                    access_token,
                    username,
                    signInDisplay: 'none',
                    usernameDisplay: 'flex',
                },
                () => {
                    that.checkAccess();
                },
            );
        } else {
            this.setState({
                access_token,
                username,
                signInDisplay: 'block',
                usernameDisplay: 'none',
            });
        }
        setInterval(() => {
            that.checkAccess();
        }, 30000);
    }

    checkAccess() {
        const that = this;
        const access_token = getCookie('access_token');
        const refresh_token = getCookie('refresh_token');

        if (!access_token && !refresh_token) {
            setCookie('access_token', '', 0);
            setCookie('refresh_token', '', 0);
            setCookie('username', '', 0);
            setCookie('color', '', 0);
            setCookie('qn_uid', '', 0);
            that.setState({
                access_token: '',
                username: '',
                signInDisplay: 'block',
                usernameDisplay: 'none',
            });
            return false;
        }

        if (!access_token && refresh_token) {
            this.exchangeToken(refresh_token);
            return false;
        }

        fetch('/auth/account/me', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json;charset=UTF-8',
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('error');

                return false;
            })

            .then((data) => {
                setCookie('username', data.username);
                that.setState({
                    access_token,
                    username: data.username,
                    signInDisplay: 'none',
                    usernameDisplay: 'flex',
                    authorities: data.authorities,
                });
            })
            .catch(() => {
                setCookie('access_token', '', 0);
                setCookie('refresh_token', '', 0);
                setCookie('username', '', 0);
                setCookie('color', '', 0);
                setCookie('qn_uid', '', 0);

                that.setState({
                    access_token: '',
                    username: '',
                    signInDisplay: 'block',
                    usernameDisplay: 'none',
                });
            });
    }

    exchangeToken(refresh_token) {
        const that = this;
        fetch('/oauth/token', {
            method: 'POST',
            headers: {
                Authorization: 'Basic Y2xpZW50OnNlY3JldA==',
                'Content-Type': 'application/x-www-form-urlencoded',
            },

            body: 'grant_type=refresh_token&refresh_token={refresh_token}&scope=read,write,trust'.replace(
                '{refresh_token}',
                refresh_token,
            ),
        })
            .then((response) => {
                switch (response.status) {
                    case 200:
                        return response.json();
                        break;
                    default:
                        setCookie('access_token', '', 0);
                        setCookie('refresh_token', '', 0);
                        setCookie('username', '', 0);
                        setCookie('color', '', 0);
                        setCookie('qn_uid', '', 0);
                        that.setState({
                            access_token: '',
                            username: '',
                            signInDisplay: 'block',
                            usernameDisplay: 'none',
                        });
                        return false;
                        break;
                }
            })
            .then((response) => {
                this.fetchAccount(response, true);
            })
            .catch((error) => {});
    }

    fetchAccount(data, rememberMe) {
        const that = this;
        const {access_token} = data;
        const refresh_token = rememberMe ? data.refresh_token : '';
        const expires_in = moment().add(data.expires_in, 'seconds').format();
        const token_expires = data.expires_in;

        fetch('/auth/account/me', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json;charset=UTF-8',
            },
        })
            .then((response) => {
                switch (response.status) {
                    case 200:
                        return response.json();
                        break;

                    default:
                        setCookie('access_token', '', 0);
                        setCookie('refresh_token', '', 0);
                        setCookie('username', '', 0);
                        setCookie('color', '', 0);
                        setCookie('qn_uid', '', 0);

                        that.setState({
                            access_token: '',
                            username: '',
                            signInDisplay: 'block',
                            usernameDisplay: 'none',
                        });
                        throw response;
                        break;
                }
            })

            .then((data) => {
                setCookie('access_token', access_token, token_expires);
                setCookie('username', data.username, token_expires);
                if (rememberMe) {
                    setCookie('refresh_token', refresh_token, 2592000);
                }
                that.setState({
                    access_token,
                    username: data.username,
                    signInDisplay: 'none',
                    usernameDisplay: 'flex',
                    authorities: data.authorities,
                });
            });
    }

    handleClick = () => {
        setCookie('access_token', '', 0);
        setCookie('refresh_token', '', 0);
        setCookie('username', '', 0);
        setCookie('color', '', 0);
        setCookie('qn_uid', '', 0);
        this.setState({
            access_token: '',
            username: '',
            signInDisplay: 'block',
            usernameDisplay: 'none',
        });
    };

    render() {
        const admin =
            this.state.authorities.includes('ADMIN') || this.state.authorities.includes('MANAGER');
        const logoClass = PlatformConfigurator.getLogoClass();
        return (
            <LanguageConsumer>
                {({setLanguage, checkLanguage}) => (
                    <header>
                        <div className="top_header">
                            <div className="logoWrapper">
                                <Link className={`logo ${logoClass}`} to="/">
                                    Home
                                </Link>
                            </div>
                            <Menu />
                            <MobileMenu />
                            <div
                                className="langWrapper"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <a
                                    style={{
                                        display: this.state.signInDisplay,
                                    }}
                                    className="myAccountLink"
                                    rel="noopener noreferrer"
                                    href={`${window.location.origin.toString()}/personalpage/login`}
                                    target="_self"
                                >
                                    <TranslatableAccount language={{}} />
                                </a>

                                <div
                                    className="usernameLinkWrapper"
                                    style={{
                                        display: this.state.usernameDisplay,
                                    }}
                                >
                                    <span className="usernameLink">{this.state.username}</span>
                                    <span className="usernameIcon">
                                        <FontAwesomeIcon fixedWidth icon={faUser} size="sm" />
                                    </span>
                                    <span className="chevron" />
                                    <ul>
                                        <li className="usernameHeader">
                                            <span style={{color: '#d66d36'}}>
                                                {this.state.username}
                                            </span>
                                        </li>
                                        <li>
                                            <a
                                                rel="noopener noreferrer"
                                                href={`${window.location.origin.toString()}/personalpage/homepage`}
                                                target="_self"
                                            >
                                                <TranslatableLinks language={{}} />
                                            </a>
                                        </li>
                                        {admin && (
                                            <li>
                                                <a
                                                    rel="noopener noreferrer"
                                                    href={`${window.location.origin.toString()}/controlpanel/#statistics`}
                                                    target="_self"
                                                >
                                                    <TranslatableControlpanel language={{}} />
                                                </a>
                                            </li>
                                        )}
                                        <li>
                                            <span onClick={this.handleClick}>
                                                <TranslatableLogOut language={{}} />
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>
                )}
            </LanguageConsumer>
        );
    }
}

export default Header;
