import React from 'react';
import '../css/style.scss';
import Slogan from './slogan/Slogan';
import Announce from './announce/Announce';
import Proposals from './proposals/Proposals';

import AboutEcosystem from './aboutEcosystem/AboutEcosystem';
import AboutPress from './aboutPress/AboutPress';
import AboutWinners from './aboutWinners/AboutWinners';

import AboutDataset from './aboutDatasets/AboutDataset';
import AboutDatasetAdditional from './aboutDatasets/AboutDatasetAdditional';
import AboutPrize from './AboutPrize/AboutPrize';
import AboutCompanyHistory from './aboutCompanyHistory/CompanyHistory';

function HomepageQuantiacs() {
    return (
        <>
            <Announce />
            <Slogan />
            <Proposals />
            <a name="prize"></a>
            <AboutCompanyHistory />
            <AboutPrize />
            <AboutEcosystem />
            <AboutDataset />
            <AboutDatasetAdditional />
            <AboutPress />
            <AboutWinners />
        </>
    );
}

export default HomepageQuantiacs;
