/* eslint-disable */
let debug = true;

function init(account) {
    console.log('reddit init', account);
    !(function (w, d) {
        if (!w.rdt) {
            var p = (w.rdt = function () {
                p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments);
            });
            p.callQueue = [];
            var t = d.createElement('script');
            (t.src = 'https://www.redditstatic.com/ads/pixel.js'), (t.async = !0);
            var s = d.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(t, s);
        }
    })(window, document);
    //Reddit Pixel
    rdt('init', account);
    rdt('track', 'PageVisit');
}

function event(name) {
    if (name === 'REGISTRATION_SUCCESS') {
        rdt('track', 'SignUp');
    }
    if (name === 'STRATEGY_EDIT_BUTTON_CLICK') {
        rdt('track', 'AddToWishlist');
    }
    if (name === 'SUBMIT_STRATEGY_SUCCESS') {
        rdt('track', 'AddToCart');
    }
}

function userInfo(info) {
    console.log('reddit userInfo not supported');
    // rdt('track', 'info', info);
}

function hit(url) {
    console.log('reddit hit not supported');
    // rdt('track', 'hit', {url:url});
}

export {init, event, hit, userInfo};
