import React from 'react';
import '../../css/style.scss';
import styled from 'styled-components';

import imgUsdEur from '../../img/homepage/main_slogan.webp';

const SloganContainer = styled.div`
    background: #141526 url(${imgUsdEur}) round;
    display: flex;
    align-items: center;
    padding: 20px;

    @media (max-width: 768px) {
        border-radius: 0;
        width: 100%;
        height: auto !important;
        align-items: flex-end;
        padding: 0 2px;
    }
`;

const SloganContent = styled.div`
    margin: 15px auto;
    max-width: 1380px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;

    h1 {
        font-size: 2.5rem;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        font-family: 'Gotham Pro Regular', sans-serif;
        text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);

        @media (max-width: 768px) {
            font-size: 1.8rem;
            margin-top: 1.5rem;
        }
    }

    p {
        padding: 30px 0;
        color: #fff;
    }

    .slogan-links {
        text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);

        .separator {
            margin: 0 8px;
            color: #fff; /* or any color you prefer */
        }

        a {
            color: #1bbafb;
            text-decoration: none;
            font-weight: 600;
            transition: color 0.3s ease-in-out, text-shadow 0.3s ease-in-out,
                transform 0.3s ease-in-out;
            text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.7);
        }

        a:hover {
            color: #1a9fe0;
            text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
            text-decoration: underline;
            transform: translateY(-2px);
        }
    }

    .slogan-text {
        line-height: 1.5;
        color: #fff;
        text-align: center;

        .cta-button {
            display: inline-block;
            color: #fff;
            border: none;
            cursor: pointer;
            padding: 20px 50px;
            text-decoration: none;
            font-size: 1.3em;
            background: #1bbafb;
            border-radius: 10px;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
            transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;

            @media (max-width: 768px) {
                padding: 10px 30px;
                font-size: 1.4rem;
            }
        }

        .cta-button:hover {
            background: #1a9fe0;
            transform: translateY(-3px);
        }
    }

    .slogan-video {
        max-height: 60vh;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 20px;
        text-align: center;

        @media (min-width: 1050px) {
            margin-top: 0;
            min-width: 600px;
        }
    }

    @media (min-width: 1050px) {
        flex-direction: row;
        justify-content: space-evenly;
        align-items: stretch;
        padding-bottom: 25px;

        .slogan-text {
            max-width: 606px;
            margin: auto 0;
        }
    }
`;

function Slogan() {
    return (
        <SloganContainer>
            <SloganContent>
                <div className="slogan-text">
                    <h1>The World's Leading Quant Platform</h1>

                    <p>
                        <a
                            className="cta-button"
                            href="/personalpage/login"
                            aria-label="Create a Free Account on Quantiacs"
                        >
                            Create Free Account
                        </a>
                    </p>

                    <p className="slogan-links">
                        <a href="/contest">Quant contests</a> <span className="separator"> • </span>{' '}
                        <a href="/community/">Community</a> <span className="separator"> • </span>{' '}
                        <a href="https://github.com/quantiacs/toolbox">Open-source backtester</a>
                    </p>
                </div>
                <div className="slogan-video">
                    <iframe
                        width="100%"
                        height="380"
                        src="https://www.youtube.com/embed/mt7WXNj6dKU"
                        title="Quick Start Quantiacs YouTube Video"
                        style={{border: 0}}
                        loading="lazy"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </SloganContent>
        </SloganContainer>
    );
}

export default Slogan;
