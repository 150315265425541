import {quantiacs} from './quantiacs';

const quantnetName = 'quantnet';
const quantiacsName = 'quantiacs';

export default class PlatformConfigurator {
    static getNameCurrentVersionPlatform() {
        const {hostname} = window.location;
        if (
            hostname === 'quantiacs.com' ||
            hostname === 'quantiacs.io' ||
            hostname === 'staging-2.quantiacs.io' ||
            hostname === 'localhost'
        ) {
            return quantiacsName;
        }
        if (hostname === 'quantnet.io' || hostname === 'staging.quantnet-ai.ru') {
            return quantnetName;
        }
        return quantiacsName;
    }

    static getBaseLocalisationDir() {
        if (PlatformConfigurator.isQuantnet()) {
            return '';
        }
        if (PlatformConfigurator.isQuantiacs()) {
            return quantiacs;
        }
        new Error('Undefined platform config');
    }

    static getConfig() {
        if (PlatformConfigurator.isQuantiacs()) {
            return quantiacs;
        }
        new Error('Undefined platform config');
    }

    static isQuantnet() {
        return PlatformConfigurator.getNameCurrentVersionPlatform() === quantnetName;
    }

    static isQuantiacs() {
        return PlatformConfigurator.getNameCurrentVersionPlatform() === quantiacsName;
    }

    static getCurrency() {
        return PlatformConfigurator.getConfig().general.currency;
    }

    static getLogoClass() {
        return PlatformConfigurator.getConfig().general.logoClassName;
    }

    static getContactGitHubLink() {
        if (PlatformConfigurator.getContactConfig().gitHub) {
            return PlatformConfigurator.getContactConfig().gitHub.link;
        }
        return '';
    }

    static getContactGitHubName() {
        if (PlatformConfigurator.getContactConfig().gitHub) {
            return PlatformConfigurator.getContactConfig().gitHub.title;
        }
        return '';
    }

    static getContactEmailLink() {
        if (PlatformConfigurator.getContactConfig().emailSupport) {
            return PlatformConfigurator.getContactConfig().emailSupport.link;
        }
        return '';
    }

    static getContactEmailName() {
        if (PlatformConfigurator.getContactConfig().emailSupport) {
            return PlatformConfigurator.getContactConfig().emailSupport.title;
        }
        return '';
    }

    static getContactTelegramLink() {
        if (PlatformConfigurator.getContactConfig().telegram) {
            return PlatformConfigurator.getContactConfig().telegram.link;
        }
        return '';
    }

    static getContactTelegramName() {
        if (PlatformConfigurator.getContactConfig().telegram) {
            return PlatformConfigurator.getContactConfig().telegram.title;
        }
        return '';
    }

    static getContactConfig() {
        return PlatformConfigurator.getConfig().account.contacts;
    }
}
