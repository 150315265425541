import React from 'react';
import '../../css/style.scss';
import styled from 'styled-components';
import {stringsContest} from '../../Localisation';
import AboutCompetitionPrizeCalculator from './AboutCompetitionPrizeCalculator';

const ContestDiv = styled.div`
    li,
    .dark_theme_strong {
        color: white;
    }

    .section_ul {
        color: white;
    }

    .prize_red {
        color: #ff0000;
    }
`;

function AboutPrize() {
    return (
        <ContestDiv className="quantiacs_in_the_press">
            <section className="dark_theme">
                <h2 className="section_header">{stringsContest.prizeTitle}</h2>
                <p className="section_text">
                    Quantiacs allocates{' '}
                    <strong className="prize_red">2M USD to each contest</strong>. Seven winning
                    strategies for each contest are eligible for allocations and quants
                    <a href="/contest/15#profit">
                        <strong className="dark_theme_strong">earn 10% of the profits</strong>
                    </a>{' '}
                    generated by their systems without any downside risk for them. Allocations are
                    distributed as follows:
                </p>
                <ul className="section_text section_ul">
                    <li className="prize_red">1M USD to the winner of each contest;</li>
                    <li>500k USD to the 2nd;</li>
                    <li>250k USD to the 3rd;</li>
                    <li>100k USD to the 4th;</li>
                    <li>50k USD to the 5th, 6th and 7th.</li>
                </ul>

                <AboutCompetitionPrizeCalculator />
            </section>
        </ContestDiv>
    );
}

export default AboutPrize;
