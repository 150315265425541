import React from 'react';
import '../css/style.scss';
import {LanguageContext, stringsMenu} from '../Localisation';

import {getCookie, setCookie} from '../cookies';

class LanguageProvider extends React.Component {
    state = {
        language: stringsMenu.getInterfaceLanguage(),
    };

    checkLanguage = () => {
        const language = getCookie('language')
            ? getCookie('language')
            : stringsMenu.getInterfaceLanguage();
        this.state.language = language.substring(0, 2);
        return language.substring(0, 2);
    };

    setLanguage = (e) => {
        setCookie('language', e.target.className);
        const access_token = getCookie('access_token');
        if (access_token) {
            fetch('/auth/account/me', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    'Content-Type': 'application/json;charset=UTF-8',
                },
                cache: 'default',
                body: JSON.stringify({
                    lang: e.target.className.toUpperCase(),
                }),
            });
        }
        return this.setState({language: e.target.className});
    };

    updateLanguage = (e) => {
        setCookie('language', e.target.value);
        return this.setState({language: e.target.value});
    };

    render() {
        this.checkLanguage();
        return (
            <LanguageContext.Provider
                value={{
                    language: this.state.language,
                    updateLanguage: this.updateLanguage,
                    setLanguage: this.setLanguage,
                    checkLanguage: this.checkLanguage,
                }}
            >
                {this.props.children}
            </LanguageContext.Provider>
        );
    }
}
export default LanguageProvider;
