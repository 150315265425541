export const utm = (link, sep) => {
    const parsed = new URL(unescape(link));
    const query = parsed.query || '';
    sep = sep || '&';
    const utmRegx = new RegExp(`((utm_[^=]+)=([^\\${sep}]+))`, 'gi');
    const matched = query.match(utmRegx) || [];
    return (
        matched.reduce((o, u) => {
            const kV = u.split('=');
            o[kV[0]] = kV[1];
            return o;
        }, {}) || {}
    );
};
