import React from 'react';
import '../css/style.scss';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faLinkedin,
    faMedium,
    faReddit,
    faTwitter,
    faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {LanguageConsumer, stringsMenu} from '../Localisation';
import PlatformConfigurator from '../0_configuration/PlatformConfigurator';

function TranslatableContest(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                return (props.language[language] = stringsMenu.contest);
            }}
        </LanguageConsumer>
    );
}
function TranslatableTerms(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                return (props.language[language] = stringsMenu.policyLink_conditions);
            }}
        </LanguageConsumer>
    );
}
function TranslatablePersonalData(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                return (props.language[language] = stringsMenu.policyLink_personaldata);
            }}
        </LanguageConsumer>
    );
}
function TranslatableCopyright(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                props.language[language] = stringsMenu.policy_copyright;
                return (
                    <span className="copywrite">
                        {stringsMenu.policy_copyright_1} {new Date().getFullYear()}{' '}
                        {stringsMenu.policy_copyright_2}
                    </span>
                );
            }}
        </LanguageConsumer>
    );
}

function TranslatableCookies(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                return (props.language[language] = stringsMenu.policyLink_cookies);
            }}
        </LanguageConsumer>
    );
}

function TranslatableCareer(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                return (props.language[language] = stringsMenu.career);
            }}
        </LanguageConsumer>
    );
}
function TranslatableAccount(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                return (props.language[language] = stringsMenu.myAccount);
            }}
        </LanguageConsumer>
    );
}

function TranslatableLearn(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                return (props.language[language] = stringsMenu.learn);
            }}
        </LanguageConsumer>
    );
}

function Footer() {
    const logoClass = PlatformConfigurator.getLogoClass();
    const documentationLink = `/documentation/en/`;
    return (
        <footer>
            <div className="wrapper">
                <div className="main_footerWrapper">
                    <div className="main_footer">
                        <nav className="footerMenu">
                            <ul>
                                <li>
                                    <Link
                                        className="nav-link"
                                        to={documentationLink}
                                        target="_self"
                                    >
                                        <TranslatableLearn language={{}} />
                                    </Link>
                                </li>
                                <li>
                                    <Link className="nav-link" to="/contest" target="_self">
                                        <TranslatableContest language={{}} />
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="main_footer">
                        <nav className="footerMenu">
                            <ul>
                                <li>
                                    <Link to="/career" className="nav-link">
                                        <TranslatableCareer language={{}} />
                                    </Link>
                                </li>
                                <li>
                                    <a
                                        className="nav-link"
                                        rel="noopener noreferrer"
                                        href={`${window.location.origin.toString()}/personalpage/login`}
                                        target="_self"
                                    >
                                        <TranslatableAccount language={{}} />
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="main_footer">
                        <nav className="footerMenu">
                            <ul>
                                <li>
                                    <a
                                        className="nav-link"
                                        rel="noopener noreferrer"
                                        href="/personalinfo"
                                        target="_self"
                                    >
                                        <TranslatablePersonalData language={{}} />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="nav-link"
                                        rel="noopener noreferrer"
                                        href="/termsofuse"
                                        target="_self"
                                    >
                                        <TranslatableTerms language={{}} />
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="main_footer">
                        <nav className="footerMenu">
                            <ul>
                                <li>
                                    <a
                                        className="nav-link"
                                        rel="noopener noreferrer"
                                        href="/cookies"
                                        target="_self"
                                    >
                                        <TranslatableCookies language={{}} />
                                    </a>
                                </li>
                                <li>
                                    <div className="socButtonWrapper">
                                        <a
                                            rel="noopener noreferrer"
                                            title="Facebook"
                                            aria-label="Facebook"
                                            href="https://www.facebook.com/quantiacs/"
                                            target="_self"
                                            className="FB"
                                        >
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={faFacebook}
                                                size="2x"
                                            />
                                        </a>

                                        <a
                                            rel="noopener noreferrer"
                                            title="Twitter"
                                            aria-label="Twitter"
                                            href="https://twitter.com/Quantiacs"
                                            target="_self"
                                            className="TW"
                                        >
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={faTwitter}
                                                size="2x"
                                            />
                                        </a>
                                        <a
                                            rel="noopener noreferrer"
                                            title="Linkedin"
                                            aria-label="Linkedin"
                                            href="https://www.linkedin.com/company/quantiacs"
                                            target="_self"
                                            className="Linkedin"
                                        >
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={faLinkedin}
                                                size="2x"
                                            />
                                        </a>
                                        <a
                                            rel="noopener noreferrer"
                                            title="Youtube"
                                            aria-label="Youtube"
                                            href="https://www.youtube.com/channel/UChVK03CwYHSXwsBiPuSi9GA"
                                            target="_self"
                                            className="TW"
                                        >
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={faYoutube}
                                                size="2x"
                                            />
                                        </a>
                                        <a
                                            rel="noopener noreferrer"
                                            title="Medium"
                                            aria-label="Medium"
                                            href="https://quantiacs.medium.com/"
                                            target="_self"
                                            className="TW"
                                        >
                                            <FontAwesomeIcon fixedWidth icon={faMedium} size="2x" />
                                        </a>
                                        <a
                                            rel="noopener noreferrer"
                                            title="Reddit"
                                            aria-label="Reddit"
                                            href="https://www.reddit.com/r/quantiacs/"
                                            target="_self"
                                            className="TW"
                                        >
                                            <FontAwesomeIcon fixedWidth icon={faReddit} size="2x" />
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="copywriteWrapper ">
                    <div className="main_footer">
                        <Link className={`logo ${logoClass}`} to="/">
                            Home
                        </Link>
                    </div>
                    <TranslatableCopyright language={{}} />
                </div>
            </div>
        </footer>
    );
}

export default Footer;
