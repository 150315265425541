import React, {useState} from 'react';
import '../css/style.scss';
import {Link} from 'react-router-dom';
import {LanguageConsumer, stringsMenu} from '../Localisation';
import {getCookie} from '../cookies';

function TranslatableHome(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                return (props.language[language] = stringsMenu.main);
            }}
        </LanguageConsumer>
    );
}

function TranslatableContact(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                return (props.language[language] = stringsMenu.contacts);
            }}
        </LanguageConsumer>
    );
}

function TranslatableContest(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                return (props.language[language] = stringsMenu.contest);
            }}
        </LanguageConsumer>
    );
}
function TranslatableLearn(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                return (props.language[language] = stringsMenu.learn);
            }}
        </LanguageConsumer>
    );
}

function TranslatableFaq(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsMenu.setLanguage(language);
                return (props.language[language] = stringsMenu.faq);
            }}
        </LanguageConsumer>
    );
}

function MobileMenu() {
    const [isOpen, setIsOpen] = useState(false);
    const documentationLink = `/documentation/en/`;
    return (
        <div className="headerWrapper">
            <div id="wrapper">
                <label
                    id="nav-icon3"
                    className={isOpen ? 'menu-icon open' : 'menu-icon'}
                    htmlFor="toggle"
                    onClick={(e) => setIsOpen(!isOpen)}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </label>
                <input type="checkbox" id="toggle" checked={isOpen} readOnly />
                <nav onClick={(e) => setIsOpen(!isOpen)}>
                    <ul>
                        <li>
                            <Link className="nav-link" to="/">
                                <TranslatableHome language={{}} />
                            </Link>
                        </li>
                        <li>
                            <Link className="nav-link" to="/contest">
                                <TranslatableContest language={{}} />
                            </Link>
                        </li>
                        <li>
                            <a className="nav-link" href={documentationLink} target="_self">
                                <TranslatableLearn language={{}} />
                            </a>
                        </li>

                        <li>
                            <Link className="nav-link" rel="noopener noreferrer" to="/contacts">
                                <TranslatableContact language={{}} />
                            </Link>
                        </li>
                        <li>
                            <Link className="nav-link" to="/career">
                                Career
                            </Link>
                        </li>
                        <li>
                            <Link className="nav-link" to="/faq">
                                <TranslatableFaq language={{}} />
                            </Link>
                        </li>
                        <li>
                            <Link className="nav-link" to="/leaderboard">
                                Systems
                            </Link>
                        </li>
                        <li>
                            <a
                                className="nav-link quantiacs_community_link"
                                target="_self"
                                href="/community"
                            >
                                Community
                            </a>
                        </li>
                        <li>
                            <a
                                className="nav-link"
                                href="https://legacy.quantiacs.com/Systems.aspx"
                                target="_blank"
                            >
                                Q1-Q14 Contests
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default MobileMenu;
