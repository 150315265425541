import React from 'react';
import '../../css/style.scss';
import {LanguageConsumer} from '../../Localisation';
import {ReactComponent as YourSvg} from '../../img/homepage/dark2.svg';

class AboutEcosystem extends React.Component {
    render() {
        return <LanguageConsumer>{({language}) => this.renderEcosystem()}</LanguageConsumer>;
    }

    renderEcosystem() {
        return (
            <div className="ecosystem_container">
                <h2 className="section_header">Ecosystem</h2>
                <div className="ecosystem_main">
                    <div className="ecosystem_left">
                        <div className="ecosystem_tab">
                            <div className="ecosystem_tab_header">Python</div>
                            <p>Code trading strategies in Python with ease.</p>
                        </div>
                        <div className="ecosystem_tab">
                            <div className="ecosystem_tab_header">Install Libraries</div>
                            <p>Customize environments with libraries like TensorFlow.</p>
                        </div>
                        <div className="ecosystem_tab">
                            <div className="ecosystem_tab_header">Cloud Servers</div>
                            <p>
                                Run strategies on the Quantiacs cloud (8GB RAM, 1 Xeon E5-1650 core
                                per system) or{' '}
                                <a href="/documentation/en/user_guide/local_development.html">
                                    develop locally
                                </a>{' '}
                                and submit.
                            </p>
                        </div>
                    </div>

                    <div className="ecosystem_center">
                        <YourSvg />
                    </div>

                    <div className="ecosystem_right">
                        <div className="ecosystem_tab">
                            <div className="ecosystem_tab_header">Jupyter</div>
                            <p>
                                Use Jupyter Notebook and JupyterLab for coding and plotting results.
                            </p>
                        </div>
                        <div className="ecosystem_tab">
                            <div className="ecosystem_tab_header">Market Data</div>
                            <p>
                                Join contests for{' '}
                                <a href="/documentation/en/user_guide/data.html">
                                    Stocks, Crypto, Futures
                                </a>
                                .
                            </p>
                        </div>
                        <div className="ecosystem_tab">
                            <div className="ecosystem_tab_header">Free Research</div>
                            <p>
                                <a href="https://github.com/quantiacs">Open-source libraries</a> for
                                local algorithm testing.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutEcosystem;
