import React from 'react';
import '../css/style.scss';
import {LanguageConsumer, stringsCookies} from '../Localisation';

function TranslatableMainTitle(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsCookies.setLanguage(language);
                props.language[language] = stringsCookies;

                return <h1>{stringsCookies.mainTitle}</h1>;
            }}
        </LanguageConsumer>
    );
}
function TranslatableLastVersion(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsCookies.setLanguage(language);
                return (props.language[language] = stringsCookies.lastRevised);
            }}
        </LanguageConsumer>
    );
}

function TranslatableConclusionTitle(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsCookies.setLanguage(language);
                props.language[language] = stringsCookies;

                return <h3 className="section_header">{stringsCookies.conclusionTitle}</h3>;
            }}
        </LanguageConsumer>
    );
}
function TranslatableConclusionText(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsCookies.setLanguage(language);
                props.language[language] = stringsCookies.conclusionText;

                return <p className="section_text">{stringsCookies.conclusionText}</p>;
            }}
        </LanguageConsumer>
    );
}

function TranslatableAbout(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsCookies.setLanguage(language);
                props.language[language] = stringsCookies;

                return (
                    <>
                        <h3 className="section_header">{stringsCookies.aboutTitle}</h3>
                        <p className="section_text">{stringsCookies.aboutText}</p>
                        <p className="section_text">
                            {stringsCookies.aboutText_personaldata}{' '}
                            <a href="/personalinfo">{stringsCookies.aboutText_personaldataLink}</a>
                        </p>
                    </>
                );
            }}
        </LanguageConsumer>
    );
}

function TranslatableWhatCookies(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsCookies.setLanguage(language);
                props.language[language] = stringsCookies;

                return (
                    <>
                        <h3 className="section_header">{stringsCookies.whatTitle}</h3>
                        <p className="section_text">{stringsCookies.whatText}</p>
                    </>
                );
            }}
        </LanguageConsumer>
    );
}

function TranslatableHowCookies(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsCookies.setLanguage(language);
                props.language[language] = stringsCookies;

                return (
                    <>
                        <h3 className="section_header">{stringsCookies.howTitle}</h3>
                        <p className="section_text">{stringsCookies.howText}</p>
                    </>
                );
            }}
        </LanguageConsumer>
    );
}
function TranslatableListCookies(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsCookies.setLanguage(language);
                props.language[language] = stringsCookies;

                return (
                    <>
                        <h3 className="section_header section_list">{stringsCookies.tableTitle}</h3>

                        <ol className="section_list">
                            <li>
                                <p className="li_header">{stringsCookies.tablePreference_cookie}</p>
                                <p className="li_text">{stringsCookies.tablePreference_purpose}</p>
                            </li>

                            <li>
                                <p className="li_header">{stringsCookies.tableNecessary_cookie}</p>
                                <p className="li_text">{stringsCookies.tableNecessary_purpose}</p>
                            </li>

                            <li>
                                <p className="li_header">{stringsCookies.tableStatistics_cookie}</p>
                                <p className="li_text">{stringsCookies.tableStatistics_purpose}</p>
                            </li>

                            <li>
                                <p className="li_header">{stringsCookies.tableMarketing_cookie}</p>
                                <p className="li_text">{stringsCookies.tableMarketing_purpose}</p>
                            </li>
                        </ol>
                    </>
                );
            }}
        </LanguageConsumer>
    );
}

function TranslatableTableCookies(props) {
    return (
        <LanguageConsumer>
            {({language}) => {
                stringsCookies.setLanguage(language);
                props.language[language] = stringsCookies;

                return (
                    <>
                        <h3 className="section_header section_table">
                            {stringsCookies.tableTitle}
                        </h3>
                        <table className="section_table">
                            <tbody>
                                <tr>
                                    <th>{stringsCookies.tableHeader_cookie}</th>
                                    <th>{stringsCookies.tableHeader_purpose}</th>
                                </tr>

                                <tr>
                                    <td>{stringsCookies.tablePreference_cookie}</td>
                                    <td>{stringsCookies.tablePreference_purpose}</td>
                                </tr>

                                <tr>
                                    <td>{stringsCookies.tableNecessary_cookie}</td>
                                    <td>{stringsCookies.tableNecessary_purpose}</td>
                                </tr>

                                <tr>
                                    <td>{stringsCookies.tableStatistics_cookie}</td>
                                    <td>{stringsCookies.tableStatistics_purpose}</td>
                                </tr>

                                <tr>
                                    <td>{stringsCookies.tableMarketing_cookie}</td>
                                    <td>{stringsCookies.tableMarketing_purpose}</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                );
            }}
        </LanguageConsumer>
    );
}

function CookiesQuantiacs() {
    return (
        <main>
            <div className="termsWrapper">
                <section className="personal-data">
                    <TranslatableMainTitle language={{}} />
                    <p className="lastVersion">
                        <TranslatableLastVersion language={{}} />
                    </p>

                    <br />
                    <TranslatableAbout language={{}} />
                    <br />
                    <TranslatableWhatCookies language={{}} />
                    <br />
                    <TranslatableHowCookies language={{}} />
                    <br />
                    <TranslatableTableCookies language={{}} />
                    <TranslatableListCookies language={{}} />

                    <br />
                    <TranslatableConclusionTitle language={{}} />
                    <TranslatableConclusionText language={{}} />
                    <ol>
                        <li className="section_text">
                            <a
                                href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=en"
                                className="link-external"
                            >
                                Google Chrome
                            </a>
                        </li>
                        <li className="section_text">
                            <a
                                href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
                                className="link-external"
                            >
                                Microsoft Edge
                            </a>
                        </li>
                        <li className="section_text">
                            <a
                                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                                className="link-external"
                            >
                                Mozilla Firefox
                            </a>
                        </li>
                        <li className="section_text">
                            <a
                                href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
                                className="link-external"
                            >
                                Microsoft Internet Explorer
                            </a>
                        </li>
                        <li className="section_text">
                            <a
                                href="https://www.opera.com/help/tutorials/security/privacy/"
                                className="link-external"
                            >
                                Opera
                            </a>
                        </li>
                        <li className="section_text">
                            <a
                                href="https://support.apple.com/en-gb/safari"
                                className="link-external"
                            >
                                Apple Safari
                            </a>
                        </li>
                    </ol>
                </section>
            </div>
        </main>
    );
}

export default CookiesQuantiacs;
